import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ansatte from '../../media/employees/ansatte.jpg';

export default function HomePage() {
    return (
        <div className='home-page'>
            <div className='banner'>
                <div className='banner-text'>
                    <h1>Velkommen til oss!</h1>
                </div>
            </div>
            <Container>
                <Row>
                    <Col>
                        <p>Økonomitjenesten.no AS holder til i trivelige lokaler på Smøla, Aure sentrum, og Leira på Tustna. Vi utfører tjenester for næringsdrivende i forskjellige bransjer og størrelser.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Regnskap er så mye mer enn tall! Vi ønsker å være en god samarbeidspartner for kunden, som er fremtidsrettet og løsningsorientert, og samtidig har fokus på kvalitet og lønnsomhet. Ønsker du å gjøre noe av regnskapsarbeidet selv, så kan vi være din controller.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img src={ansatte} alt="Økonomitjenesten AS" className="employees"></img>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="tjenester">
                            <h2>Tjenester</h2>
                            <li>Regnskap</li>
                            <li>Lønn</li>
                            <li>Fakturering</li>
                            <li>Rådgivning, selskapsetablering, budsjettering mm.</li>
                            <li>Årsavslutning med ligningspapirer og offentlig regnskap</li>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className='centerText'>
                        <p>Økonomitjenesten.no AS ble etablert i 1988 og har i dag 19 ansatte på heltid og 3 på deltid.</p><i class="fa-sharp fa-regular fa-browsers"></i>
                        <p><a href="http://www.kommunenvar.no/?div_id=90&pag_id=92&art_id=1310" target="_blank" rel="noopener noreferrer">&#8594; Trykk her for å se hvordan det var for 15 år siden &#8592;</a></p>
                    </Col>
                </Row>
            </Container>
            <Container className='google-map-container'>
                {/* <Row>
                    <Col>
                        <h1>Du finner oss i våre lokaler</h1>
                    </Col>
                </Row> */}
                <Row>
                    <Col md={6}>
                        <h2>Smøla</h2>
                        <iframe title='Google Smøla' className='google-map' src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=R%C3%A5dhusveien%2015,%206570%20Sm%C3%B8la+(%C3%98konomitjenesten.no%20AS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    </Col>
                    <Col md={6}>
                        <h2 className='orange'>Tustna</h2>
                        <iframe title='Google Tustna' className='google-map' src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=M%C3%A5lavegen%206+(%C3%98konomitjenesten%20AS%20avd.%20Tustna)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <h2 className='orange'>Aure</h2>
                        <iframe title='Google Tustna' className='google-map' src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Aure%20kj%C3%B8pesenter+(%C3%98konomitjenesten%20AS%20avd.%20Aure)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    </Col>
                    <Col md={6}>
                        <h2>Kristiansund</h2>
                        <iframe title='Google Kristiansund' className='google-map' src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Fosnagata%2012,%206509%20Kristiansund+(%C3%98konomitjenesten%20AS%20avd.%20Kristiansund)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}